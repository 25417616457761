import { useEffect, useState } from 'react';
import { Button, Card, IconButton, Popover, Typography } from '@panviva/panviva-react-ui';
import './FilterStyles.scss';
import * as moment from 'moment';

export const DateFilter = (props: any) => {

    const { field, firstFilterValue, secondFilterValue, onFilterApply, onClear } = props;

    const [fromDate, setFromDate] = useState(firstFilterValue);
    const [toDate, setToDate] = useState(secondFilterValue);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const id = open ? 'popper-dropdown' : undefined;

    useEffect(()=>{
        setOpen(Boolean(anchorEl))
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDateWithoutTimeAsGmt = (value: Date, endOfDay: boolean) => {
        const inputDate = value;

        // if (endOfDay) {
        //     inputDate.setHours(23, 59, 59);
        // } else {
        //     inputDate.setHours(0, 0, 0);
        // }

        return moment(inputDate).format('YYYY-MM-DD');
    };

    const onFirstFilterChange = (event: any) => {
        event?.stopPropagation();
        const maxDate = moment(new Date()).format("YYYY-MM-DD");
        const value = event.target?.value;
        if (value > maxDate) {
          event.target.value = maxDate;
          setFromDate(maxDate);
        }else{
            setFromDate(value);
        }
    };

    const onSecondFilterChange = (event: any) => {
        event?.stopPropagation();
        const maxDate = moment(new Date()).format("YYYY-MM-DD");
        const value = event.target?.value;
        if (value > maxDate) {
          event.target.value = maxDate;
          setToDate(maxDate);
        }else{
            setToDate(value);
        }
    };

    const onButtonClick = (buttonName: string) => {
        if (buttonName === 'apply' && (fromDate || toDate)) {
            const format_fromDate = getDateWithoutTimeAsGmt(new Date(fromDate), false);
            const format_toDate = getDateWithoutTimeAsGmt(new Date(toDate), true);
            const obj = {...fromDate && {from: {value: format_fromDate, operator: 'ge'}}, ...toDate && {to: {value: format_toDate, operator:'le'}}}
            onFilterApply(field.property, obj, "eq", true);
        } else {
            onClear(field.property); setFromDate(null); setToDate(null);
        }
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton icon={(firstFilterValue || secondFilterValue) ? "filter-active" : "filter-inactive"} onClick={handleClick}/>

            <Popover id={id} open={open} anchorEl={anchorEl} onClose={()=>handleClose()} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Card style={{ display: 'flex' }}>
                    <Typography
                        className='filter-title'
                        variant="body_2"
                        >
                        {field.name}
                    </Typography>
                    <div className='date-filter-container'>
                        <div>
                        <label>From:</label>                       
                        <input 
                            type="date"
                            id="from-date"
                            max={new Date().toJSON().split('T')[0]}
                            placeholder="From Date"
                            value={fromDate}
                            onChange={(newValue) => onFirstFilterChange(newValue)}>
                        </input>                        
                        </div>
                        <div>
                        <label>To:</label>                      
                        <input
                            type="date"
                            id="to-date"
                            max={new Date().toJSON().split('T')[0]}
                            placeholder="To Date"
                            value={toDate}
                            onChange={(newValue) => onSecondFilterChange(newValue)}>
                        </input>
                        </div>
                    </div>
                    <div className='filter-button'>                        
                        <Button variant='text' onClick={() => onButtonClick('clear')}>clear</Button>&nbsp;
                        <Button variant='text' onClick={() => onButtonClick('apply')}>apply</Button>
                    </div>
                </Card>
            </Popover>
        </>
    );
};
