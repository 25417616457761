import { IAnalyticsRequestParams } from '../container/AnalyticsPage';

export const formatRequestParams = (paramsObj:IAnalyticsRequestParams) =>{

    const obj = JSON.parse(JSON.stringify(paramsObj));

    const filterQueryList:any = [];

    obj.filter.filters.forEach((filter:any)=>{
        filterQueryList.push(getQueryString(filter));
    })

    obj.filter.filters = filterQueryList.join(' and ');

    return obj;

}

const getQueryString = (filter:any) =>{
    switch(filter.operator){
        case 'contains':
            return `search.ismatch('${filter.value}*','${filter.field}')`
        case 'eq':
            let queryStringOr:string = '';
            filter.value?.forEach((val:string, index: number)=>{
                queryStringOr += `${filter.field} eq '${val}'${index < filter.value?.length - 1 ? ' or ' : ''}`
            })
            return queryStringOr;
        case 'array eq':
            let queryString:string = '';
            filter.value?.forEach((val:string, index: number)=>{
                queryString += `t eq '${val}'${index < filter.value?.length - 1 ? ' or ' : ''}`
            })
            return `${filter.field}/any(t: ${queryString})`;
        case 'ge':
            return `${filter.field} ge ${filter.value}`;
        case 'le':
            return `${filter.field} le ${filter.value}`;
        default:
            return ''
    }
}