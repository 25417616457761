import { useEffect, useState } from 'react';
import { Button, Card, Chip, IconButton, Popover, Select, Typography } from '@panviva/panviva-react-ui';
import './FilterStyles.scss';
import { Tooltip } from 'react-tooltip';
import i18n from '../../../../ts/i18n';

export const SelectFilter = (props: any) => {
    const { field, value, asyncloader, onFilterApply, onClear } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState(value || []);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const loading = open && options.length === 0;
    const [openPopper, setOpenPopper] = useState(false);
    const id = open ? 'popper-async-dropdown' : undefined;
    const [chips, setChips] = useState<any>(null);
    const [inputText, setInputText] = useState('');

    useEffect(()=>{
        setOpenPopper(Boolean(anchorEl))
    },[anchorEl])

    useEffect(()=>{
        {!field.searchable && setFilteredOptions(options || [])};
      }, [options])

    useEffect(()=>{
        const chips = selectedValue.map((item:any) => <Chip className="select-menu-chips" label={item} action actionIcon="cancel" onAction={() => onChipRemove(item)} />);
        setChips(chips);
    }, [selectedValue])

    const onChipRemove = (value:string) =>{
        setSelectedValue(selectedValue?.filter((item:any) => item !== value));
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if(field.menuOptions?.length){
                setOptions(field.menuOptions);
            }else{
                await asyncloader(field.property).then((data: any) => {
                if (active) {
                    setOptions(data?.facets[0]?.groups.map((obj:any)=> obj.key) || []);
                    }
                });
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    const onTextChange = (event:any) => {
        const inputValue = event.target.value;
        if(inputValue.length >= 3){
            setInputText(event.target.value);
            const filterOptions = options?.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase())) 
            setFilteredOptions(filterOptions);
        }else{
            setInputText('');
            setFilteredOptions([])
        }
      }

    const handleClickSingleSelection = (option:string) => {
        setSelectedValue([option]);
    }

    const handleClickMultiSelection = (event:any) => {
        const selectedIndex = selectedValue.findIndex(
          (item: any) => item === event.target.value
      );
      let newSelected: any[] = [];
    
      if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedValue, event.target.value);
      } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedValue.slice(1));
      } else if (selectedIndex === selectedValue.length - 1) {
          newSelected = newSelected.concat(selectedValue.slice(0, -1));
      } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedValue.slice(0, selectedIndex),
            selectedValue.slice(selectedIndex + 1)
          );
      }
      setSelectedValue(newSelected);
    }

    const onButtonClick = (buttonName: string) => {
        if (buttonName === 'apply' && selectedValue?.length > 0) {
            onFilterApply(field.property, selectedValue, field.hideSort ?  "array eq" : "eq");
        } else {
            onClear(field.property);
            setSelectedValue([]);
        }
        setAnchorEl(null);
        setFilteredOptions(options || []);
    };

    return (
        <>
            <IconButton icon={value ? "filter-active" : "filter-inactive"} onClick={handleClick}
                data-tooltip-id="keyword-filter-tooltip"
                data-tooltip-content={field.name === "Keywords" ? i18n.t('analytics.filterTooltip') : undefined}/>

            <Tooltip
                    id={"keyword-filter-tooltip"}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />

            <Popover id={id} open={openPopper} anchorEl={anchorEl} onClose={() => handleClose()} anchorOrigin={{vertical:'bottom', horizontal:'left'}}>
                <Card style={{ display: 'flex' }}>
                    <Typography
                        className='filter-title'
                        variant="body_2"
                        >
                        {field.name}
                    </Typography>                

                <Select
                    loading={loading}
                    multiple={field.multiSelection}
                    closeOnSelect={!field.multiSelection}
                    enableType={field.searchable}
                    label={'select'}
                    selected={selectedValue}
                    onTextChange={() => onTextChange(event)}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    chips={chips}
                    style={{width:"220px"}}>    
                {
                    filteredOptions?.length > 0 ? filteredOptions?.map((option:any)=>{
                    return field.multiSelection ? <div key={option} className='menu-item'>
                        <input 
                        type="checkbox"
                        value={option} 
                        checked={selectedValue?.includes(option)} onChange={handleClickMultiSelection}/>&nbsp;
                        <label className='menu-label' title={option}>{option}</label>
                    </div> : 
                    <div key={option} className='menu-item' onClick={() => handleClickSingleSelection(option)}>
                        <label className='menu-label' title={option}>{option}</label>
                    </div>
                    })
                    :
                    <div>{inputText ? 'no results found' : 'type 3 or more characters'}</div>
                }
                </Select>

                    <div className='filter-button'>
                        <Button variant='text' onClick={() => onButtonClick('clear')}>clear</Button>&nbsp;
                        <Button variant='text' onClick={() => onButtonClick('apply')}>apply</Button>
                    </div>
                </Card>
            </Popover>
        </>
    );

    };
