import { AuthState } from '@okta/okta-auth-js';
import * as classNames from 'classnames';
import * as classnames from 'classnames';
import * as React from "react";
import appHistory from "../../../AppHistory";
import i18n from "../../../i18n";
import { LoadingIndicator } from "../../../Shared/LoadingIndicator";
import { DORCTooltip } from "../../../Shared/Tooltip";
import { CompositeResponseList } from "../CompositeResponseList/CompositeResponseList";
import './SearchResponses.scss';

export interface ISearchResponsesProps {
  getResponsesLinkedToDocument: (docId: number, pageNumber: number, pageSize: number, sortOrder: string, auth: AuthState | null) => any;
  responses: Api.IPagedResult<Api.ICompositeResponseViewModel>;
  documentId: number;
  isDocumentIdValid: boolean;
  fetching: boolean;
  failed: boolean;
  searched: boolean;
  error: string;
  getLinkedResponsesForExternalSources: (selectedTenant: string, query: string, pageNumber: number, pageSize: number, auth: AuthState | null) => any;
  DefaultResponsesStateForResponses: () => any;
  DefaultResponsesStateForExternalSources: () => any;
  isQueryValid: boolean,
  externalSourcesFetching: boolean,
  externalSourcesSearched: boolean,
  externalSourcesFailed: boolean,
  enableExternalDataSources: boolean,
  externalSourcesResponses: Api.IExternalSourcesSearchReducerResponse,
  selectedTenant: string,
  authState: AuthState | null;
}

export interface ISearchResponseState {
  docId: number,
  searchDisabled: boolean,
  isDocumentIdValid: boolean,
  error: string,
  isPanvivaCoreEnabled: boolean,
  isExternalSourcesEnabled: boolean,
  query: string,
  externalSourcesError: string
}

class SearchResponses extends React.Component<ISearchResponsesProps, ISearchResponseState> {
  constructor(props: ISearchResponsesProps) {
    super(props);

    this.state = {
      searchDisabled: true,
      isDocumentIdValid: true,
      docId: props.documentId ? props.documentId : 0,
      error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error,
      isPanvivaCoreEnabled: true,
      isExternalSourcesEnabled: false,
      query: "",
      externalSourcesError: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.hanldeNewButtonClick = this.hanldeNewButtonClick.bind(this);
    this.handleExternalSourcesClick = this.handleExternalSourcesClick.bind(this);
    this.handlePanvivaCoreClick = this.handlePanvivaCoreClick.bind(this);
    this.handleExternalSourcesSubmit = this.handleExternalSourcesSubmit.bind(this);
    this.handleExternalSourcesPageSizeChange = this.handleExternalSourcesPageSizeChange.bind(this);
  }

  public componentDidUpdate(prevProps: ISearchResponsesProps) {
    if (prevProps && prevProps !== this.props) {
      if (this.props.documentId !== prevProps.documentId) {
        this.setState({
          docId: this.props.documentId,
          searchDisabled: this.props.documentId === 0,
          isDocumentIdValid: this.props.isDocumentIdValid,
          error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error
        });
      }

      if (prevProps.isDocumentIdValid !== this.props.isDocumentIdValid
        && !this.props.isDocumentIdValid
        || (prevProps.error !== this.state.error)) {
        this.setState({
          error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error
        });
      }

      if (prevProps.isQueryValid !== this.props.isQueryValid) {
        this.setState({
          externalSourcesError: i18n.t('manage.invalidQueryMsg')
        });
      }
    }
  }

  public render() {
    const newButtonEnabled = (this.props.searched && this.props.documentId > 0 && this.props.isDocumentIdValid && !!this.props.responses) ||
      (this.props.externalSourcesSearched && this.props.isQueryValid && !!this.props.externalSourcesResponses);
    //  const warningMsg = (this.props.searched && this.props.responses && !this.props.responses.rowCount)
    //    ?  i18n.t("manage.noResponseFoundMsg") : i18n.t("manage.createNewWarningMessage");

    return (
      <div>
        <LoadingIndicator busy={this.props.fetching || this.props.externalSourcesFetching} />
        {!this.props.fetching &&
          <ul className="search-response">
            <li className="info" data-value={i18n.t("numbers.one")}>
              <div className='header'>{i18n.t("manage.searchResponseTitle")}</div><div></div>
              <div className='description'>{i18n.t("manage.searchInstruction")}</div>
              {this.props.enableExternalDataSources
                ? <>
                  <div className="margin-left-20 margin-top-10">
                    <button className={classNames("btn btn-primary no-right-border-radius", this.state.isPanvivaCoreEnabled ? "disable-button" : "")} onClick={this.handleExternalSourcesClick}>
                      {i18n.t("manage.externalSources")}
                    </button>
                    <button className={classnames("btn btn-primary no-left-border-radius", this.state.isExternalSourcesEnabled ? "disable-button" : "")} onClick={this.handlePanvivaCoreClick}>
                      {i18n.t("manage.panvivaCore")}
                    </button>
                  </div>
                  {
                    this.state.isPanvivaCoreEnabled ?
                      <form noValidate={true} className="form-inline" onSubmit={this.handleSubmit}>
                        <div className="input-group input-group-lg search-group">
                          <input type="text" aria-label="search-input" autoFocus={true} className={classnames('form-control', { 'is-invalid': this.state.error })}
                            value={this.state.docId === 0 ? '' : this.state.docId}
                            onChange={(e) => this.handleInputChange(e, true)}
                            placeholder={i18n.t("manage.searchPlaceholderText")} />
                          <button type="submit" className="btn btn-primary" disabled={this.state.searchDisabled}>
                            {i18n.t("manage.search")}
                          </button>
                          <div className={classnames('invalid-feedback', { 'no-display': this.props.isDocumentIdValid || this.props.responses.results.length !== 0 })}>
                            {this.state.error}
                          </div>
                          {!this.props.isDocumentIdValid && this.props.responses.results.length > 0 && <DocumentDeletedNotification docId={this.props.documentId} />}
                        </div>
                      </form> :
                      <form noValidate={true} className="form-inline" onSubmit={this.handleExternalSourcesSubmit}>
                        <div className="input-group input-group-lg search-group">

                          <input type="text" aria-label="search-input" autoFocus={true} className={classnames('form-control', { 'is-invalid': this.state.externalSourcesError })}
                            value={this.state.query}
                            onChange={(e) => this.handleInputChange(e, false)}
                            placeholder={i18n.t("manage.searchPlaceholderTextForExternalSources")} />
                          <button type="submit" className="btn btn-primary" disabled={this.state.searchDisabled}>
                            {i18n.t("manage.search")}
                          </button>
                          <div className={classnames('invalid-feedback', { 'no-display': this.props.isQueryValid })}>
                            {this.state.externalSourcesError}
                          </div>
                        </div>
                      </form>
                  }
                </>
                : <form noValidate={true} className="form-inline" onSubmit={this.handleSubmit}>
                  <div className="input-group input-group-lg search-group">
                    <input type="text" aria-label="search-input" autoFocus={true} className={classnames('form-control', { 'is-invalid': this.state.error })}
                      value={this.state.docId === 0 ? '' : this.state.docId}
                      onChange={(e) => this.handleInputChange(e, true)}
                      placeholder={i18n.t("manage.searchPlaceholderText")} />
                    <button type="submit" className="btn btn-primary" disabled={this.state.searchDisabled}>
                      {i18n.t("manage.search")}
                    </button>
                    <div className={classnames('invalid-feedback', { 'no-display': this.props.isDocumentIdValid || this.props.responses.results.length !== 0 })}>
                      {this.state.error}
                    </div>
                    {!this.props.isDocumentIdValid && this.props.responses.results.length > 0 && <DocumentDeletedNotification docId={this.props.documentId} />}
                  </div>
                </form>
              }
              {this.props.responses.rowCount > 0 &&
                <CompositeResponseList handleExternalSourcesPageSizeChange={this.handleExternalSourcesPageSizeChange} handlePageSizeChange={this.handlePageSizeChange} docId={this.props.documentId} responses={this.props.responses} authState={this.props.authState} isPanvivaCoreEnabled={this.state.isPanvivaCoreEnabled} externalSourcesResponses={this.props.externalSourcesResponses} />
              }

              {this.props.externalSourcesResponses.totalCount > 0 &&
                <CompositeResponseList handleExternalSourcesPageSizeChange={this.handleExternalSourcesPageSizeChange} handlePageSizeChange={this.handlePageSizeChange} docId={this.props.documentId} externalSourcesResponses={this.props.externalSourcesResponses} authState={this.props.authState} isPanvivaCoreEnabled={this.state.isPanvivaCoreEnabled} responses={this.props.responses} />
              }
            </li>

            <li className="info" data-value={i18n.t("numbers.two")}>

              <div className='header'>{i18n.t("manage.createNewResponseTitle")}</div><div></div>
              <span className='description'>{i18n.t("manage.createNewResponseInstruction")}</span>
              <span className='information-circle'><DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={i18n.t("manage.createNewWarningMessageTooltip")} placement="right" type="info"
                iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip" /></span>


              <div className='new-response-btn'><button type="button"
                disabled={!newButtonEnabled}
                onClick={this.hanldeNewButtonClick}
                className="create-new-btn btn btn-primary">
                {i18n.t("manage.createNew")}
              </button>
              </div>


            </li>
          </ul>
        }
      </div>
    )
  }

  private handlePageSizeChange(pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) {
    this.props.getResponsesLinkedToDocument(this.state.docId, pageNumber, itemPerPage, sortOrder, auth);
  }

  private handleExternalSourcesPageSizeChange(pageNumber: number, itemPerPage: number, auth: AuthState) {
    this.props.getLinkedResponsesForExternalSources(this.props.selectedTenant, this.state.query, pageNumber, itemPerPage, this.props.authState);
  }

  private hanldeNewButtonClick(e: React.FormEvent<HTMLButtonElement>) {
    appHistory.push(`/manage/0?documentId=${this.props.documentId || this.state.docId}`);
  }

  private handleInputChange(e: React.FormEvent<HTMLInputElement>, isPanvivaCore: boolean) {
    if (!e.currentTarget.value) {
      this.setState({
        searchDisabled: true,
        docId: 0
      })
    } else {
      if (isPanvivaCore) {
        const documentId = parseInt(e.currentTarget.value, 10);
        if (documentId) {
          this.setState({
            searchDisabled: false,
            docId: parseInt(e.currentTarget.value, 10),
            error: ""
          })
        } else {
          this.setState({
            searchDisabled: true,
            docId: 0,
            error: i18n.t('manage.invalidDocIdMsg')
          });
        }
      } else {
        this.setState({
          searchDisabled: false,
          query: e.currentTarget.value,
          error: ""
        })
      }
    }
  }

  private handleSubmit(event: any) {
    event.preventDefault();
    appHistory.push(`/manage/search/${this.props.responses.currentPage}?docId=${this.state.docId}`);
    this.props.getResponsesLinkedToDocument(this.state.docId, 1, this.props.responses.pageSize, this.props.responses.sortOrder, this.props.authState);
  }

  private handleExternalSourcesSubmit(event: any) {
    event.preventDefault();
    appHistory.push(`/manage/search/${this.props.responses.currentPage}?query=${this.state.query}`);
    this.props.getLinkedResponsesForExternalSources(this.props.selectedTenant, this.state.query, 1, this.props.responses.pageSize, this.props.authState);
  }

  private handleExternalSourcesClick() {
    this.setState({ isExternalSourcesEnabled: true, isPanvivaCoreEnabled: false, searchDisabled: true, query: "" });
    this.props.DefaultResponsesStateForResponses();

  }

  private handlePanvivaCoreClick() {
    this.setState({ isPanvivaCoreEnabled: true, isExternalSourcesEnabled: false, searchDisabled: true, docId: 0 });
    this.props.DefaultResponsesStateForExternalSources();

  }
}

function DocumentDeletedNotification(props: any) {
  return (
    <div className="document-deleted-notification">
      <i className="fa fa-exclamation" />
      <p className="notification-text">{i18n.t("manage.documentDeletedWithResponsesMessage", { docId: props.docId })}</p>
    </div>
  )
}

export { SearchResponses };
